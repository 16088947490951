import React, { useState, useEffect } from "react";
import Desktop from "./layouts/Desktop";
import Tablet from "./layouts/Tablet";
import Mobile from "./layouts/Mobile";

const App = () => {
  const [device, setDevice] = useState("mobile");

  // Função para identificar o tamanho da tela
  const updateDevice = () => {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      setDevice("desktop");
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      setDevice("tablet");
    } else {
      setDevice("mobile");
    }
  };

  // Hook para detectar mudanças no tamanho da tela
  useEffect(() => {
    updateDevice(); // Chamada inicial
    window.addEventListener("resize", updateDevice); // Atualiza ao redimensionar
    return () => {
      window.removeEventListener("resize", updateDevice); // Cleanup
    };
  }, []);

  return (
    <div className="items-center justify-center h-screen">
      {device === "desktop" && <Desktop />}
      {device === "tablet" && <Mobile />}
      {device === "mobile" && <Mobile />}
    </div>
  );
};

export default App;
