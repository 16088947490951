import React, { useState } from "react";

const ContributionOption = ({ amount, isSelected, onClick }) => {
  return (
    <div
      className={`relative w-full md:w-[245px] mt-10 min-h-[152px] p-6 border-2 rounded-lg transition-all duration-200 cursor-pointer ${
        isSelected
          ? "border-[#bf8a49] bg-[#fdf8f3]"
          : "border-gray-300 bg-white"
      }`}
      onClick={() => onClick(amount)}
    >
      {isSelected && (
        <div className="absolute top-[-40px] w-full left-1/2 transform -translate-x-1/2 flex items-center justify-center gap-2 p-2 rounded-t-lg bg-[#bf8a49] text-white">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.74053 17.5765L4.04053 11.8765L5.46553 10.4515L9.74053 14.7265L18.9155 5.55151L20.3405 6.97651L9.74053 17.5765Z"
              fill="currentColor"
            />
          </svg>
          <span className="text-[15px] uppercase font-bold">Escolhida</span>
        </div>
      )}
      <button
        className="w-full text-left"
        aria-label={`Contribuir com ${amount}`}
      >
        <p className="font-medium">Quero contribuir com</p>
        <p className="text-[32px] text-[#bf8a49] font-bold">R$ {amount},00</p>
      </button>
    </div>
  );
};

const ContributionOptions = ({ onSelect }) => {
  const [selectedAmount, setSelectedAmount] = useState(null);

  const handleCardClick = (amount) => {
    setSelectedAmount(amount);
    if (onSelect) {
      onSelect(amount); // Dispara a função recebida como prop
    }
  };

  const options = [50, 100, 150, 200, 1000];

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4 sm:p-6">
      <div className="flex flex-wrap gap-4 justify-center">
        {options.map((amount) => (
          <ContributionOption
            key={amount}
            amount={amount}
            isSelected={selectedAmount === amount}
            onClick={handleCardClick}
          />
        ))}
      </div>
    </div>
  );
};

export default ContributionOptions;
