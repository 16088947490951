import React, { useState } from "react";

const FAQComponent = ({ faqs }) => {
  return (
    <div className="space-y-4 px-4 md:px-8 lg:px-16">
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="border border-gray-300 rounded-lg shadow-md transition-all w-full max-w-[1100px] mx-auto"
      data-state={isOpen ? "open" : "closed"}
    >
      <div
        className="cursor-pointer flex justify-between items-center p-4  "
        onClick={toggleAccordion}
        style={{ height: "74px" }}
      >
        <p className="text-[15px] text-white uppercase font-bold">{question}</p>
        <div>
          {isOpen ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 13H19V11H5V13Z" fill="white" />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 5H13V19H11V5ZM5 11H19V13H5V11Z" fill="white" />
            </svg>
          )}
        </div>
      </div>

      <div
        className="text-white overflow-hidden transition-all duration-300 ease-in-out"
        style={{
          maxHeight: isOpen ? "200px" : "0",
          opacity: isOpen ? 1 : 0,
          padding: isOpen ? "16px" : "0 16px"
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FAQComponent;
